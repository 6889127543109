<template>
  <div>
    <app-bar
      ref="refAppBar"
      @on_back="onBack"
      @bulk_update="bulkUpdate"
    ></app-bar>
    <v-container fluid>
      <v-row>
        <v-col cols="12" md="6">
          <div class="text-subtitle-2 pa-1 blue--text blue lighten-5 mb-2">
            最近登録した順で表示しています。更新したいキャストをチェックしてください。
          </div>
          <v-row>
            <v-col cols="6"></v-col>
            <v-col cols="6">
              <div>
                <v-text-field
                  v-model="castSearch"
                  append-icon="mdi-magnify"
                  label="（キャスト名検索）"
                  single-line
                  hide-details
                  clearable
                  dense
                  class="mb-2"
                ></v-text-field>
              </div>
            </v-col>
          </v-row>
          <v-data-table
            id="cast_table"
            :headers="castHeaders"
            :items="casts"
            :search="castSearch"
            item-key="id"
            show-select
            :single-select="false"
            v-model="castSelected"
            :mobile-breakpoint="0"
            :items-per-page="50"
            :footer-props="{
              'items-per-page-options': [50, 100, -1],
              'items-per-page-text': '表示件数',
            }"
            @click:row="rowClick"
          >
            <!-- eslint-disable-next-line -->
            <template v-slot:footer.page-text="props">
              {{ props.pageStart }} ～{{ props.pageStop }}件／全{{
                props.itemsLength
              }}件
            </template>
            <!-- 名前 -->
            <!-- eslint-disable-next-line -->
            <template v-slot:item.name="{ item }">
              <v-avatar class="ma-1 mr-3"
                ><v-img :src="setting.cdn + item.image"
              /></v-avatar>
              {{ item.name }}
            </template>
            <!-- 公開 -->
            <!-- eslint-disable-next-line -->
            <template v-slot:item.enable="{ item }">
              <div class="pa-1">
                <v-chip
                  v-if="item.is_enable"
                  class="ma-2"
                  color="primary"
                  label
                  small
                >
                  公開中
                </v-chip>
                <v-chip v-else class="ma-2" label small> 非公開 </v-chip>
              </div>
              <!-- <div class="pa-1">
                <v-switch
                  v-model="item.is_enable"
                  class="mini"
                  :label="`${item.is_enable ? '公開中' : '非公開'}`"
                  disabled
                ></v-switch>
              </div> -->
            </template>
            <template v-slot:no-data> キャストが登録されていません </template>
          </v-data-table>
        </v-col>
        <v-col cols="12" md="6">
          <div class="text-subtitle-2 pa-1 orange--text orange lighten-5 mb-2">
            更新したい媒体をチェックをしてください。
          </div>
          <v-row>
            <v-col cols="6"></v-col>
            <v-col cols="6">
              <div>
                <v-text-field
                  v-model="shopSiteSearch"
                  append-icon="mdi-magnify"
                  label="（媒体名検索）"
                  single-line
                  hide-details
                  clearable
                  dense
                  class="mb-2"
                ></v-text-field>
              </div>
            </v-col>
          </v-row>
          <v-data-table
            id="shop_site_table"
            :headers="shopSiteHeaders"
            :items="shopSites"
            :search="shopSiteSearch"
            item-key="id"
            show-select
            :single-select="false"
            v-model="shopSiteSelected"
            :mobile-breakpoint="0"
            :items-per-page="50"
            :footer-props="{
              'items-per-page-options': [50, 100, -1],
              'items-per-page-text': '表示件数',
            }"
            @click:row="rowClick"
          >
            <!-- eslint-disable-next-line -->
            <template v-slot:footer.page-text="props">
              {{ props.pageStart }} ～{{ props.pageStop }}件／全{{
                props.itemsLength
              }}件
            </template>
            <!-- 名前 -->
            <!-- eslint-disable-next-line -->
            <template v-slot:item.name="{ item }">
              <span
                :style="item.color.length != 0 ? '; color:' + item.color : ''"
                class="text-align-middle"
                >{{ item.name }}</span
              >
            </template>
            <template v-slot:no-data> 媒体が登録されていません </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>

    <!-- 一括更新ダイアログ -->
    <v-dialog
      v-model="updateDialog"
      scrollable
      persistent
      width="100%"
      max-width="900px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">キャスト情報を媒体に更新</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <div
                  class="text-subtitle-2 pa-1 orange--text orange lighten-5 mb-2"
                >
                  処理を中断した場合、更新中の次の処理以降が停止されます。※データ保護のため更新中の処理は止まりません<br />
                  「更新失敗」と表示された場合、エラー内容を
                  <span v-if="!updateDialogDisabled">
                    <router-link to="/result-log">更新結果履歴</router-link>
                  </span>
                  <span v-else>更新結果履歴</span>
                  より確認してください。
                </div>
                <!-- テーブル -->
                <v-data-table
                  :headers="updateHeaders"
                  :items="updateList"
                  :mobile-breakpoint="0"
                  item-key="uk"
                  :footer-props="{ 'items-per-page-options': [-1] }"
                  hide-default-footer
                >
                  <template v-slot:no-data>
                    表示するデータがありません
                  </template>
                  <!-- eslint-disable-next-line -->
                  <template v-slot:item.cast_name="{ item }">
                    <v-avatar v-if="item.image" class="ma-1 mr-3"
                      ><img :src="setting.cdn + item.image"
                    /></v-avatar>
                    {{ item.cast_name }}
                  </template>
                  <!-- eslint-disable-next-line -->
                  <template v-slot:item.status="{ item }">
                    {{ item.status }}
                    &nbsp;
                    <v-progress-linear
                      v-if="item.status == '更新中'"
                      indeterminate
                      color="primary"
                    ></v-progress-linear>
                    <v-progress-linear
                      v-if="item.status == '更新中(次で停止)'"
                      indeterminate
                      color="success"
                    ></v-progress-linear>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-container>
            <v-row justify="end">
              <v-col cols="6" sm="3">
                <general-button
                  btn_color="gray"
                  btn_block
                  @click-event="onBack"
                  :btn_disabled="updateDialogDisabled"
                  ><template v-slot:icon
                    ><v-icon left>mdi-arrow-left-circle</v-icon></template
                  >一覧に戻る</general-button
                >
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="6" sm="3">
                <general-button
                  btn_color="gray"
                  btn_block
                  :btn_disabled="updateDialogDisabled"
                  @click-event="cancelDialog"
                  ><template v-slot:icon
                    ><v-icon left>mdi-close-circle</v-icon></template
                  >閉じる</general-button
                >
              </v-col>
              <v-col cols="6" sm="3">
                <general-button
                  btn_color="red"
                  :btn_text="true"
                  btn_block
                  :btn_disabled="!updateDialogDisabled || updateStopBtnDisabled"
                  @click-event="stopUpdate"
                  ><template v-slot:icon
                    ><v-icon left>mdi-close-circle</v-icon></template
                  >処理を中断</general-button
                >
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <app-bar
      ref="refAppBar"
      @on_back="onBack"
      @bulk_update="bulkUpdate"
    ></app-bar>
  </div>
</template>

<script>
import {
  set,
  ref,
  reactive,
  toRefs,
  defineComponent,
} from "@vue/composition-api";
import { repositoryFactory } from "@/repository/repositoryFactory";
import { useRouter } from "@/utils";
import store from "@/store/index.js";
import setting from "@/common/setting.js";
import utilDate from "@/common/utilDate";

import appBar from "./appBar.vue";

export default defineComponent({
  components: {
    appBar,
  },
  setup(props, ctx) {
    const CastsRepository = repositoryFactory.get("casts");
    const ShopSitesRepository = repositoryFactory.get("shopSites");
    const SynchroRepository = repositoryFactory.get("synchro");

    const { router } = useRouter();
    const refAppBar = ref();

    const state = reactive({
      casts: [],
      castSearch: "",
      castSelected: [],
      castHeaders: [
        {
          text: "キャスト名",
          value: "name",
          sortable: false,
          class: "td_name",
        },
        { text: "公開/非公開", value: "enable", sortable: false },
      ],
      shopSites: [],
      shopSiteSearch: "",
      shopSiteSelected: [],
      shopSiteHeaders: [
        {
          text: "媒体名",
          value: "name",
          sortable: true,
        },
      ],
      // 更新中表示ダイアログ
      updateDialog: false,
      updateList: [],
      updateHeaders: [
        {
          text: "更新キャスト",
          value: "cast_name",
          sortable: false,
        },
        {
          text: "媒体名",
          value: "site_name",
          sortable: false,
        },
        {
          text: "ステータス",
          value: "status",
          sortable: false,
        },
      ],
      updateDialogDisabled: false,
      updateStopBtnDisabled: false,
    });

    const init = async () => {
      // ローディング表示
      store.dispatch("loadingIcon/showIcon");

      // システム内の登録キャストをstoreから取得する
      state.casts.splice(0);
      let params = new URLSearchParams();
      params.append("shop_id", store.getters["shops/currentShop"].id);
      await CastsRepository.list_update(params)
        .then((response) => {
          if (response.data) {
            Object.keys(response.data).forEach(function (key) {
              state.casts.push(response.data[key]);
            });
          }
        })
        .catch((error) => {
          throw (
            "ERROR:cast/clistTable/index.vue/init CastsRepository.list (" +
            error +
            ")"
          );
        });

      // 更新サイト一覧取得
      state.shopSites.splice(0);
      params = new URLSearchParams();
      params.append("shop_id", store.getters["shops/currentShop"].id);
      await ShopSitesRepository.list_getcast_update(params)
        .then((response) => {
          if (response.data) {
            Object.keys(response.data).forEach(function (key) {
              state.shopSites.push({
                "id": response.data[key].id,
                "name":
                  response.data[key].sites_name +
                  (JSON.parse(response.data[key].config).memo != ""
                    ? " [ " + JSON.parse(response.data[key].config).memo + " ] "
                    : ""),
                "color": JSON.parse(response.data[key].config).color,
              });
            });
          }
        })
        .catch((error) => {
          throw "ERROR:データ取得エラー (" + error + ")";
        });

      await store.dispatch("loadingIcon/hideIcon"); // ローディング非表示
    };

    init();

    // キャスト一覧に戻る
    const onBack = async () => {
      router.push({ name: "cast" });
    };

    // 取込ボタン
    const bulkUpdate = async () => {
      // 入力チェック
      if (state.castSelected.length == 0) {
        alert("更新したいキャストをチェックしてください。");
        return false;
      }
      if (state.shopSiteSelected.length == 0) {
        alert("更新したい媒体をチェックしてください。");
        return false;
      }
      // 更新前の確認
      if (
        !window.confirm("チェックした内容で媒体に更新します。よろしいですか？")
      ) {
        return false;
      }

      // ダイアログ表示
      state.updateDialog = true;
      state.updateDialogDisabled = true;
      state.updateStopBtnDisabled = false;

      // 処理リストを作成する
      let keepName = "";
      state.updateList.splice(0);
      for (const cast of state.castSelected) {
        for (const shopSite of state.shopSiteSelected) {
          state.updateList.push({
            "cast_id": cast.id,
            "cast_name": cast.name == keepName ? null : cast.name,
            "image": cast.name == keepName ? null : cast.image,
            "shop_site_id": shopSite.id,
            "site_name": shopSite.name,
            "status": "待機中",
          });
          keepName = cast.name != keepName ? cast.name : keepName;
        }
      }

      // 処理リストを参照してキャスト情報を更新する
      for (const updateItem of state.updateList) {
        if (updateItem.status == "待機中") {
          set(updateItem, "status", "更新中");

          // 更新処理
          let params = new URLSearchParams();
          params = {
            shop_id: store.getters["shops/currentShop"]["id"],
            shop_site_id: updateItem.shop_site_id,
            cast_id: updateItem.cast_id,
          };
          let result = false;
          // console.log(params);

          // キャストを更新する
          result = await SynchroRepository.sync_cast(params)
            .then((response) => {
              if (response.data) {
                if (response.data.status) {
                  return true;
                }
              } else {
                alert(response.data.message);
                return false;
              }
            })
            .catch((error) => {
              throw "ERROR:SynchroRepository.sync_cast (" + error + ")";
            });

          if (result) {
            set(updateItem, "status", "更新完了");
          } else {
            set(updateItem, "status", "更新失敗");
          }
        } else if (updateItem.status == "処理中止") {
          state.updateDialogDisabled = false;
          return;
        }
      }
      state.updateStopBtnDisabled = true;
      state.updateDialogDisabled = false;
      alert("更新処理が完了しました");
    };

    // Promiseを使う方法
    const sleepByPromise = (sec) => {
      return new Promise((resolve) => setTimeout(resolve, sec * 1000));
    };

    const rowClick = (item, row) => {
      row.select(!row.isSelected);
    };

    // キャスト一覧に戻る
    const cancelDialog = async () => {
      state.updateDialog = false;
      state.updateDialogDisabled = false;
      state.updateStopBtnDisabled = false;
    };

    // 更新処理を途中で止める
    const stopUpdate = async () => {
      if (!window.confirm("更新処理を中断します。よろしいですか？")) {
        return false;
      }
      state.updateStopBtnDisabled = true;
      for (const updateItem of state.updateList) {
        if (updateItem.status == "待機中") {
          set(updateItem, "status", "処理中止");
        }
        if (updateItem.status == "更新中") {
          set(updateItem, "status", "更新中(次で停止)");
        }
      }
      state.updateStopBtnDisabled = true;
    };

    return {
      setting,
      refAppBar,
      ...toRefs(state),
      onBack,
      bulkUpdate,
      rowClick,
      cancelDialog,
      stopUpdate,
    };
  },
});
</script>

<style scoped>
::v-deep .mini label {
  font-size: 0.75em;
}
::v-deep .td_name {
  min-width: 100px;
  width: 200px;
}
::v-deep #cast_table thead {
  background-color: #e3f2fd !important;
}
::v-deep #shop_site_table thead {
  background-color: #fff3e0 !important;
}
</style>
