var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('app-bar',{ref:"refAppBar",on:{"on_back":_vm.onBack,"bulk_update":_vm.bulkUpdate}}),_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"text-subtitle-2 pa-1 blue--text blue lighten-5 mb-2"},[_vm._v(" 最近登録した順で表示しています。更新したいキャストをチェックしてください。 ")]),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}}),_c('v-col',{attrs:{"cols":"6"}},[_c('div',[_c('v-text-field',{staticClass:"mb-2",attrs:{"append-icon":"mdi-magnify","label":"（キャスト名検索）","single-line":"","hide-details":"","clearable":"","dense":""},model:{value:(_vm.castSearch),callback:function ($$v) {_vm.castSearch=$$v},expression:"castSearch"}})],1)])],1),_c('v-data-table',{attrs:{"id":"cast_table","headers":_vm.castHeaders,"items":_vm.casts,"search":_vm.castSearch,"item-key":"id","show-select":"","single-select":false,"mobile-breakpoint":0,"items-per-page":50,"footer-props":{
            'items-per-page-options': [50, 100, -1],
            'items-per-page-text': '表示件数',
          }},on:{"click:row":_vm.rowClick},scopedSlots:_vm._u([{key:"footer.page-text",fn:function(props){return [_vm._v(" "+_vm._s(props.pageStart)+" ～"+_vm._s(props.pageStop)+"件／全"+_vm._s(props.itemsLength)+"件 ")]}},{key:"item.name",fn:function(ref){
          var item = ref.item;
return [_c('v-avatar',{staticClass:"ma-1 mr-3"},[_c('v-img',{attrs:{"src":_vm.setting.cdn + item.image}})],1),_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item.enable",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"pa-1"},[(item.is_enable)?_c('v-chip',{staticClass:"ma-2",attrs:{"color":"primary","label":"","small":""}},[_vm._v(" 公開中 ")]):_c('v-chip',{staticClass:"ma-2",attrs:{"label":"","small":""}},[_vm._v(" 非公開 ")])],1)]}},{key:"no-data",fn:function(){return [_vm._v(" キャストが登録されていません ")]},proxy:true}]),model:{value:(_vm.castSelected),callback:function ($$v) {_vm.castSelected=$$v},expression:"castSelected"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"text-subtitle-2 pa-1 orange--text orange lighten-5 mb-2"},[_vm._v(" 更新したい媒体をチェックをしてください。 ")]),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}}),_c('v-col',{attrs:{"cols":"6"}},[_c('div',[_c('v-text-field',{staticClass:"mb-2",attrs:{"append-icon":"mdi-magnify","label":"（媒体名検索）","single-line":"","hide-details":"","clearable":"","dense":""},model:{value:(_vm.shopSiteSearch),callback:function ($$v) {_vm.shopSiteSearch=$$v},expression:"shopSiteSearch"}})],1)])],1),_c('v-data-table',{attrs:{"id":"shop_site_table","headers":_vm.shopSiteHeaders,"items":_vm.shopSites,"search":_vm.shopSiteSearch,"item-key":"id","show-select":"","single-select":false,"mobile-breakpoint":0,"items-per-page":50,"footer-props":{
            'items-per-page-options': [50, 100, -1],
            'items-per-page-text': '表示件数',
          }},on:{"click:row":_vm.rowClick},scopedSlots:_vm._u([{key:"footer.page-text",fn:function(props){return [_vm._v(" "+_vm._s(props.pageStart)+" ～"+_vm._s(props.pageStop)+"件／全"+_vm._s(props.itemsLength)+"件 ")]}},{key:"item.name",fn:function(ref){
          var item = ref.item;
return [_c('span',{staticClass:"text-align-middle",style:(item.color.length != 0 ? '; color:' + item.color : '')},[_vm._v(_vm._s(item.name))])]}},{key:"no-data",fn:function(){return [_vm._v(" 媒体が登録されていません ")]},proxy:true}]),model:{value:(_vm.shopSiteSelected),callback:function ($$v) {_vm.shopSiteSelected=$$v},expression:"shopSiteSelected"}})],1)],1)],1),_c('v-dialog',{attrs:{"scrollable":"","persistent":"","width":"100%","max-width":"900px"},model:{value:(_vm.updateDialog),callback:function ($$v) {_vm.updateDialog=$$v},expression:"updateDialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v("キャスト情報を媒体に更新")])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"text-subtitle-2 pa-1 orange--text orange lighten-5 mb-2"},[_vm._v(" 処理を中断した場合、更新中の次の処理以降が停止されます。※データ保護のため更新中の処理は止まりません"),_c('br'),_vm._v(" 「更新失敗」と表示された場合、エラー内容を "),(!_vm.updateDialogDisabled)?_c('span',[_c('router-link',{attrs:{"to":"/result-log"}},[_vm._v("更新結果履歴")])],1):_c('span',[_vm._v("更新結果履歴")]),_vm._v(" より確認してください。 ")]),_c('v-data-table',{attrs:{"headers":_vm.updateHeaders,"items":_vm.updateList,"mobile-breakpoint":0,"item-key":"uk","footer-props":{ 'items-per-page-options': [-1] },"hide-default-footer":""},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_vm._v(" 表示するデータがありません ")]},proxy:true},{key:"item.cast_name",fn:function(ref){
          var item = ref.item;
return [(item.image)?_c('v-avatar',{staticClass:"ma-1 mr-3"},[_c('img',{attrs:{"src":_vm.setting.cdn + item.image}})]):_vm._e(),_vm._v(" "+_vm._s(item.cast_name)+" ")]}},{key:"item.status",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.status)+"   "),(item.status == '更新中')?_c('v-progress-linear',{attrs:{"indeterminate":"","color":"primary"}}):_vm._e(),(item.status == '更新中(次で停止)')?_c('v-progress-linear',{attrs:{"indeterminate":"","color":"success"}}):_vm._e()]}}])})],1)],1)],1)],1),_c('v-card-actions',[_c('v-container',[_c('v-row',{attrs:{"justify":"end"}},[_c('v-col',{attrs:{"cols":"6","sm":"3"}},[_c('general-button',{attrs:{"btn_color":"gray","btn_block":"","btn_disabled":_vm.updateDialogDisabled},on:{"click-event":_vm.onBack},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-arrow-left-circle")])]},proxy:true}])},[_vm._v("一覧に戻る")])],1),_c('v-spacer'),_c('v-col',{attrs:{"cols":"6","sm":"3"}},[_c('general-button',{attrs:{"btn_color":"gray","btn_block":"","btn_disabled":_vm.updateDialogDisabled},on:{"click-event":_vm.cancelDialog},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-close-circle")])]},proxy:true}])},[_vm._v("閉じる")])],1),_c('v-col',{attrs:{"cols":"6","sm":"3"}},[_c('general-button',{attrs:{"btn_color":"red","btn_text":true,"btn_block":"","btn_disabled":!_vm.updateDialogDisabled || _vm.updateStopBtnDisabled},on:{"click-event":_vm.stopUpdate},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-close-circle")])]},proxy:true}])},[_vm._v("処理を中断")])],1)],1)],1)],1)],1)],1),_c('app-bar',{ref:"refAppBar",on:{"on_back":_vm.onBack,"bulk_update":_vm.bulkUpdate}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }