<template>
  <div>
    <v-app-bar flat color="white" class="x-scroll">
      <div class="pa-2">
        <v-btn class="gray font-weight-bold" @click="onBack"
          ><v-icon left>mdi-arrow-left-circle</v-icon>戻る</v-btn
        >
      </div>
      <v-spacer></v-spacer>
      <div class="pa-2">
        <v-btn class="info font-weight-bold" @click="bulkUpdate">
          <v-icon left>mdi-sync</v-icon>一括更新</v-btn
        >
      </div>
    </v-app-bar>
  </div>
</template>

<script>
import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  setup(props, ctx) {
    const onBack = async () => {
      ctx.emit("on_back");
    };

    const bulkUpdate = async () => {
      ctx.emit("bulk_update");
    };

    return {
      props,
      onBack,
      bulkUpdate,
    };
  },
});
</script>
